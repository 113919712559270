import { FormField, Grid, TextInput, ButtonGroup, ButtonGroupButton, Modal, TextArea } from "@sede-x/shell-ds-react-framework";
import { useEffect, useState } from "react";
import * as Styled from './CurveConfiguration.style';
import Dropdown from "../../library/DropDown/Dropdown";
import { ICurveConfigurationRequest, ICurveConfigurationRequestExtensions } from "../../interfaces/ICurveConfigurationRequest";
import { ProductCurveConfigurations } from "../../services/ProductCurveConfigurations";
import { BtnGrpEnableDisableStatus, BtnGrpPublicPrivateStatus } from "../../common/enum";
import { IProductCurvePayload } from "../../interfaces/IProductCurvePayload";
import { IProductCurveFilter } from "../../interfaces/IProductCurveFilter";
import { IPaginationFilter } from "../../interfaces/IPaginationFilter";
import { ISortCriteria } from "../../interfaces/ISortCriteria";
import { ValidationMessage, ValidationHelper } from "../../common/validationHelper";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PDSCONSTANT } from "../../common/constants";
import { UserPermission } from '../../services/UserPermission';

// Areas array for Curve Configuration Side drawer
const areas = ['main .', 'subtext .', 'subtext .', 'table table'];
// Drop Down value for Put/Call 
const putCallData = ['Put', 'Call'];
// Mask Style Background Color
const maskStyle = { backgroundColor: "black" };
// Footer to set the Buttons 
let footer: any;
// Represents Filter criteria
const filterCriteria: IProductCurveFilter = { Name: null, CurveType: [], Granularity: [], PriceType: [], ContractOption: [], Profile: [], DeliveryPoint: [], Currency: [], Unit: [], Location: [], Commodity: [], DataProvider: [], Source: [], IsPrivate: false, IsActive: true, SourceCurveName: null, WildCardSearchText: null, EffectiveEndDate: null, EffectiveStartDate: null };
//Represents Pagination filter
const paginationFilter: IPaginationFilter = { PageNumber: 0, PageSize: 0, TotalRecords: 0 };
// Represents Curve Configuration request
let curveConfigurationRequest: ICurveConfigurationRequestExtensions = { _id: '', Commodity: '', ContractOption: '', Currency: '', CurveName: '', CurveType: '', DataProvider: '', CommodityDeliveryPoint: '', Granularity: '', IsActive: true, IsPrivate: false, Location: '', PriceType: '', CommodityProfile: '', InterfaceApplication: '', SourceCurveName: '', UnitOfMeasure: '', TZIdentifier: '' };
// Represents Sort Criteria
const sortCriteria: ISortCriteria[] = [];
// Side Drawer for curve configuration
export default function CurveConfigurationDrawer(drawerProps: any) {
  //object containing user permissons
  const permissions = UserPermission();

  // Set state Variables    
  const isEditable = drawerProps.selectedCurve.CurveName !== '' ? true : false;
  const headerText = drawerProps.selectedCurve.CurveName !== '' ? 'Edit Curve' : 'Create Curve';
  const existingCurveName = drawerProps.existingCurveName;
  const [granularity, setGranularity] = useState(['']);
  const [curveType, setCurveType] = useState(['']);
  const [putCall, setPutCall] = useState(['']);
  const [priceType, setPriceType] = useState(['']);
  const [currency, setCurrency] = useState(['']);
  const [secondaryCurrency, setSecondaryCurrency] = useState(['']);
  const [unitOfMeasure, setunitOfMeasure] = useState(['']);
  const [location, setLocation] = useState(['']);
  const [commodity, setCommodity] = useState(['']);
  const [deliveryPoint, setDeliveryPoint] = useState(['']);
  const [profile, setProfile] = useState(['']);
  const [statusValue, setStatusValue] = useState<string[] | string>(PDSCONSTANT.ENABLE_DEFAULT_TOGGLE_BTN);
  const [isPublic, setIsPublic] = useState<string[] | string>(PDSCONSTANT.PUBLIC_BTN_LABEL.toUpperCase());
  const [dataProvider, setDataProvider] = useState(['']);
  const [source, setSource] = useState(['']);
  const [timeZone, setTimeZone] = useState(['']);
  const [curveNameValidationErrorMessage, setcurveNameValidationErrorMessage] = useState('');
  const [granularityValidationErrorMessage, setgranularityValidationErrorMessage] = useState('');
  const [contractOptionValidationErrorMessage, setcontractOptionValidationErrorMessage] = useState('');
  const [priceTypeValidationErrorMessage, setpriceTypeValidationErrorMessage] = useState('');
  const [currencyValidationErrorMessage, setcurrencyValidationErrorMessage] = useState('');
  const [secondarCurrencyValidationErrorMessage, setsecondaryCurrencyValidationErrorMessage] = useState('');

  const [unitValidationErrorMessage, setunitValidationErrorMessage] = useState('');
  const [commodityErrorMessage, setcommodityErrorMessage] = useState('');
  const [profileErrorMessage, setprofileErrorMessage] = useState('');
  const [dataProviderErrorMessage, setdataProviderErrorMessage] = useState('');
  const [curveTypeValidationErrorMessage, setcurveTypeValidationErrorMessage] = useState('');
  const [curveTZIdValidationErrorMessage, setcurveTZIdValidationErrorMessage] = useState('');
  const [sourceErrorMessage, setsourceErrorMessage] = useState('');
  const [sourceCurveErrorMessage, setsourceCurveErrorMessage] = useState('');
  const productCurveConfigurationPayload: IProductCurvePayload = { WildCardSearchText: '', FilterCriteria: filterCriteria, PaginationFilter: paginationFilter, SortCriteria: sortCriteria }
  const [configurationRequest, setconfigurationRequest] = useState<ICurveConfigurationRequestExtensions>(curveConfigurationRequest);
  const { CreateProductCurvesConfiguration, EditProductCurvesConfiguration } = ProductCurveConfigurations();
  const [isDisabled, setDisabled] = useState(false);
  const [secondaryCurrencyRequired, setsecondaryCurrencyRequired] = useState(false);
  const [locationRequired, setlocationRequired] = useState(false);
  const [timeZoneRequired, settimeZoneRequired] = useState(false);
  const [profileRequired, setProfileRequired] = useState(false);
  useEffect(() => {
    //Reset Curve Side Drawer
    ResetCurveRequest();
    // Fetch the Product Curve data on load and on change of product curve payload
    if (drawerProps) {
      // Set dropdown value for Granularity in Create/Edit curve configuration
      setGranularity(drawerProps?.Granularity?.map((str: string, _index: number) => ({ value: str, text: str })));
      // Set dropdown value for CurveType in Create/Edit curve configuration
      setCurveType(drawerProps?.CurveType?.map((str: string, _index: number) => ({ value: str, text: str })));
      // for PutCall
      setPutCall(drawerProps?.ContractOption?.map((str: string, _index: number) => ({ value: str, text: str })));
      // Set dropdown value for Price Type in Create/Edit curve configuration
      setPriceType(drawerProps?.PriceType?.map((str: string, _index: number) => ({ value: str, text: str })));
      // Set dropdown value for Currency in Create/Edit curve configuration
      setCurrency(drawerProps?.Currency?.map((str: string, _index: number) => ({ value: str, text: str })));
      // Set dropdown value for Secondary Currency in Create/Edit curve configuration
      setSecondaryCurrency(drawerProps?.SecondaryCurrency?.map((str: string, _index: number) => ({ value: str, text: str })));
      // Set dropdown value for Unit of measure in Create/Edit curve configuration
      setunitOfMeasure(drawerProps?.Unit?.map((str: string, _index: number) => ({ value: str, text: str })));
      // Set dropdown value for Location in Create/Edit curve configuration
      setLocation(drawerProps?.Location?.map((str: string, _index: number) => ({ value: str, text: str })));
      // Set dropdown value for Commodity in Create/Edit curve configuration
      setCommodity(drawerProps?.Commodity?.map((str: string, _index: number) => ({ value: str, text: str })));
      // Set dropdown value for Commodity Delivery Point in Create/Edit curve configuration
      setDeliveryPoint(drawerProps?.DeliveryPoint?.map((str: string, _index: number) => ({ value: str, text: str })));
      // Set dropdown value for Commodity Profile in Create/Edit curve configuration
      setProfile(drawerProps?.Profile?.map((str: string, _index: number) => ({ value: str, text: str })));
      // Set dropdown value for Commodity Source in Create/Edit curve configuration
      setSource(drawerProps?.Source?.map((str: string, _index: number) => ({ value: str, text: str })));
      // Set dropdown value for Commodity Data Provider in Create/Edit curve configuration
      setDataProvider(drawerProps?.DataProvider?.map((str: string, _index: number) => ({ value: str, text: str })));
      // Set dropdown value for TimeZone in Create/Edit curve configuration
      setTimeZone(drawerProps?.TimeZone?.map((str: string, _index: number) => ({ value: str, text: str })));
    }
    if (drawerProps.selectedCurve.CurveName !== '' || drawerProps.selectedCurve.Clone === true) {
      // Resetting Source Curve Name
      if (drawerProps.selectedCurve.SourceCurveName == null) {
        drawerProps.selectedCurve.SourceCurveName = '';
      }

      // Selected curve  
      var selectedCurve = drawerProps.selectedCurve;
      // Set IsPublic status for Selected curve in drawer
      var isPublic = drawerProps.selectedCurve.IsPrivate === true ? BtnGrpPublicPrivateStatus[BtnGrpPublicPrivateStatus.Private] : BtnGrpPublicPrivateStatus[BtnGrpPublicPrivateStatus.Public];
      isPublic = " " + isPublic;
      setIsPublic(isPublic);
      // Set IsActive status for Selected curve
      var isActive = drawerProps.selectedCurve.IsActive === true ? BtnGrpEnableDisableStatus[BtnGrpEnableDisableStatus.Enable] : BtnGrpEnableDisableStatus[BtnGrpEnableDisableStatus.Disable];
      setStatusValue(isActive);
      // Setting empty string to Null Value
      Object.keys(selectedCurve).forEach(k => selectedCurve[k] = selectedCurve[k] === null ? '' : selectedCurve[k])
      // Set the dropdown value and other textbox value
      setconfigurationRequest(selectedCurve);

      resetValidation();
    }
    else { ResetCurveRequest(); }
    //on load of side drawer enable the save button
    setDisabled(false);
  }, [drawerProps]);
  // For reset validation in case of on click of Create curve
  const resetValidation = () => {
    setcurveNameValidationErrorMessage("");
    setgranularityValidationErrorMessage("");
    setcurveTypeValidationErrorMessage("");
    setcurveTZIdValidationErrorMessage("");
    setcommodityErrorMessage("");
    setcontractOptionValidationErrorMessage("");
    setcurrencyValidationErrorMessage("");
    setsecondaryCurrencyValidationErrorMessage("");
    setunitValidationErrorMessage("");
    setdataProviderErrorMessage("");
    setpriceTypeValidationErrorMessage("");
    setprofileErrorMessage("");
    setsourceErrorMessage("");
    setsourceCurveErrorMessage("");
    setsecondaryCurrencyValidationErrorMessage("");
  }
  // Reset Curve request on click of Reset button
  const ResetCurveRequest = () => {
    //Resetting Curve 
    curveConfigurationRequest = { _id: '', Commodity: '', ContractOption: '', Currency: '', CurveName: '', CurveType: '', DataProvider: '', CommodityDeliveryPoint: '', Granularity: '', IsActive: true, IsPrivate: false, Location: '', PriceType: '', CommodityProfile: '', InterfaceApplication: '', SourceCurveName: '', UnitOfMeasure: '', TZIdentifier: '' };
    // Resetting Public Flag
    setIsPublic(" " + BtnGrpPublicPrivateStatus[BtnGrpPublicPrivateStatus.Public]);
    // Resetting Enabled Flag
    setStatusValue(BtnGrpEnableDisableStatus[BtnGrpEnableDisableStatus.Enable]);
    resetValidation();
    setconfigurationRequest(curveConfigurationRequest);
  }
  // Reset Validation on Change of input and dropdown
  const resetValidationOnChange = (res: ICurveConfigurationRequest) => {
    // Resetting validation for curve name if there is some input
    if (res.CurveName !== '') { setcurveNameValidationErrorMessage(""); }
    // Resetting validation for Granularity if there is some input
    if (res.Granularity !== '') { setgranularityValidationErrorMessage(""); }
    // Resetting validation for CurveType if there is some input
    if (res.CurveType !== '') { setcurveTypeValidationErrorMessage(""); }
    // Resetting validation for TZIdentifier if there is some input
    if (res.TZIdentifier !== '') { setcurveTZIdValidationErrorMessage(""); }
    // Resetting validation for Commodity if there is some input
    if (res.Commodity !== '') { setcommodityErrorMessage(""); }
    // Resetting validation for ContractOption if there is some input
    if (res.ContractOption !== '') { setcontractOptionValidationErrorMessage(""); }
    // Resetting validation for Currency if there is some input
    if (res.Currency !== '') { setcurrencyValidationErrorMessage(""); }
    // Resetting validation for Secondary Currency if there is some input
    if (res.SecondaryCurrency !== '') { setsecondaryCurrencyValidationErrorMessage(""); }
    // Resetting validation for unit if there is some input
    if (res.UnitOfMeasure !== '') { setunitValidationErrorMessage(""); }
    // Resetting validation for DataProvider if there is some input
    if (res.DataProvider !== '') { setdataProviderErrorMessage(""); }
    // Resetting validation for PriceType if there is some input
    if (res.PriceType !== '') { setpriceTypeValidationErrorMessage(""); }
    // Resetting validation for Profile if there is some input
    if (res.CommodityProfile !== '') { setprofileErrorMessage(""); }
    // Resetting validation for Source if there is some input
    if (res.InterfaceApplication !== '') { setsourceErrorMessage(""); }
    // Resetting validation for SourceCurveName if there is some input
    if (res.SourceCurveName !== '') { setsourceCurveErrorMessage(""); }
    // Resetting validation for Secondary Currency if there is some input
    if (res.SecondaryCurrency !== res.Currency) { setsecondaryCurrencyValidationErrorMessage(""); }
  }
  const setValidation = (res: ICurveConfigurationRequest): boolean => {
    let isValid: boolean = true;
    // Validating the curvename 
    if (IsNotValid(res.CurveName)) { setcurveNameValidationErrorMessage(ValidationMessage.CURVE_NAME_REQUIRED); isValid = false; }
    // Validating the granularity
    if (IsNotValid(res.Granularity)) { setgranularityValidationErrorMessage(ValidationMessage.GRANULARITY_REQUIRED); isValid = false; }
    // Validating the curve type
    if (IsNotValid(res.CurveType)) { setcurveTypeValidationErrorMessage(ValidationMessage.CURVE_TYPE_REQUIRED); isValid = false; }
    // Validating the timezone identifier
    if (IsNotValid(res.TZIdentifier)) { setcurveTZIdValidationErrorMessage(ValidationMessage.TZIDENTIFIER_REQUIRED); isValid = false; }
    // Validating the commodity
    if (IsNotValid(res.Commodity)) { setcommodityErrorMessage(ValidationMessage.COMMODITY_REQUIRED); isValid = false; }
    // Validating the contract option
    if (IsNotValid(res.ContractOption) && configurationRequest.CurveType === PDSCONSTANT.VOLATILITY_CURVE_TYPE_VALUE) { setcontractOptionValidationErrorMessage(ValidationMessage.CONTRACT_OPTION_REQUIRED); isValid = false; }
    // Validating the currency
    if (IsNotValid(res.Currency)) { setcurrencyValidationErrorMessage(ValidationMessage.CURRENCY_REQUIRED); isValid = false; }
    // Validating the secondary currency
    if (IsNotValid(res.SecondaryCurrency) && configurationRequest.Commodity === PDSCONSTANT.FX_COMMODITY_NAME_VALUE) { setsecondaryCurrencyValidationErrorMessage(ValidationMessage.SECONDARY_CURRENCY_REQUIRED); isValid = false; }
    // Validating the currency
    if (IsNotValid(res.UnitOfMeasure)) { setunitValidationErrorMessage(ValidationMessage.UNIT_REQUIRED); isValid = false; }
    // Validating the data provider
    if (IsNotValid(res.DataProvider)) { setdataProviderErrorMessage(ValidationMessage.DATA_PROVIDER_REQUIRED); isValid = false; }
    // Validating the price type
    if (IsNotValid(res.PriceType)) { setpriceTypeValidationErrorMessage(ValidationMessage.PRICE_TYPE_REQUIRED); isValid = false; }
    // Validating the profile
    if (IsNotValid(res.CommodityProfile) && configurationRequest.Commodity === PDSCONSTANT.POWER_COMMODITY_NAME_VALUE && !PDSCONSTANT.PDS_HOURLY_GRANULARITITES.includes(configurationRequest.Granularity ?? "")) { setprofileErrorMessage(ValidationMessage.PROFILE_REQUIRED); isValid = false; }
    // Validating the source
    if (IsNotValid(res.InterfaceApplication)) { setsourceErrorMessage(ValidationMessage.SOURCE_REQUIRED); isValid = false; }
    // Validating the source name
    if (IsNotValid(res.SourceCurveName)) { setsourceCurveErrorMessage(ValidationMessage.SOURCE_CURVE_NAME_REQUIRED); isValid = false; }
    // Validating the secondary same currency
    if (IsSameCurrency(res.Currency, res.SecondaryCurrency)) { setsecondaryCurrencyValidationErrorMessage(ValidationMessage.SAME_SECONDARY_CURRENCY_AS_CURRENCY); isValid = false; }

    return isValid;
  }

  const IsSameCurrency = (currencyData: string | undefined, seondaryCurrencyData: string | undefined): boolean => {
    return (currencyData === seondaryCurrencyData) ? true : false;
  }

  const IsNotValid = (data: string | undefined): boolean => {
    return (data === undefined || data == null || data.length <= 0) ? true : false;
  }
  // CreateOrEditCurve on click of save button
  const CreateOrEditCurve = () => {
    try {
      // if button disabled not serve the request
      if (isDisabled) {
        return;
      }
      var res = configurationRequest;
      resetValidationOnChange(res);
      const isValid = setValidation(res);
      if (isValid) {
        setDisabled(true);
        CreateOrEditCurveRequest(res);
      }
    }
    catch (error) {
      // In case of error in Create or edit request enable the save button
      setDisabled(false);
    }
  }
  // API call for creating the curve or editing the curve 
  const CreateOrEditCurveRequest = (res: ICurveConfigurationRequest) => {

    //Trimming the End spaces and special charectors for all the input fields.
    res.CurveName = res.CurveName?.trimEnd().replace(/​/g, "");
    res.SourceCurveName = res.SourceCurveName?.trimEnd().replace(/​/g, "");
    res.ExistingCurveName = existingCurveName;
    if (res._id === '' || res._id === undefined) {
      // Checking if curve Exist or not      
      CreateProductCurvesConfiguration(res).then(result => {
        ResetCurveRequest();
        drawerProps.onClose(true, PDSCONSTANT.CURVE_CONFIG_CREATE_SUCCESS);
        setDisabled(false);
      }).catch((err) => {
        toast.error(err.response.data.ErrorMessage, { position: toast.POSITION.TOP_CENTER });
        setDisabled(false);
      })
    }
    else {

      // Edit the Product Curve
      EditProductCurvesConfiguration(res).then(result => {
        drawerProps.onClose(true, PDSCONSTANT.CURVE_CONFIG_UPDATE_SUCCESS);
      }).catch((err) => {
        if (err.response.data.ErrorMessage) {
          toast.error(err.response.data.ErrorMessage, { position: toast.POSITION.TOP_CENTER });
          setDisabled(false);
        }
        else {
          toast.error(err.response.data.title, { position: toast.POSITION.TOP_CENTER });
          setDisabled(false);
        }
      })
    }
  }
  // On Drawer close
  const onClose = (): void => {
    drawerProps.onClose();
  };
  // On change of status 
  const handleStatusChange = (selectedValues: string[] | string): void => {
    configurationRequest.IsActive = (selectedValues === BtnGrpEnableDisableStatus[BtnGrpEnableDisableStatus.Enable] ? true : false);
    setStatusValue(selectedValues);
  };
  // on change of IsPublic  radio button
  const handleIsPublicChange = (selectedValues: any): void => {
    configurationRequest.IsPrivate = (selectedValues.trim() === BtnGrpPublicPrivateStatus[BtnGrpPublicPrivateStatus.Private] ? true : false);
    setIsPublic(selectedValues);
  };

  // On Change of textbox input calls this method, this will remove the first special charecter 
  const onTextBoxInputChange = (fieldName: string, value: any) => {
    if (value !== '') {
      value = ValidationHelper().RecursiveRemoveFirstSpecialChar(value.trimLeft());
    }
    onInputChange(fieldName, value);
  };


  // On Change of input/dropdown call this method
  const onInputChange = (fieldName: string, value: any) => {

    configurationRequest[fieldName as keyof ICurveConfigurationRequest] = value;
    // Resetting the Profile value in case of commodities other than Power
    if (configurationRequest.Commodity !== PDSCONSTANT.POWER_COMMODITY_NAME_VALUE) {
      configurationRequest.CommodityProfile = '';
    }
    // Resetting the Contract option value in case of Curve type other than Volatility
    if (configurationRequest.CurveType !== PDSCONSTANT.VOLATILITY_CURVE_TYPE_VALUE) {
      configurationRequest.ContractOption = '';
    }
    // Resetting the Secondary Currency value in case of commodities other than FX
    if (configurationRequest.Commodity !== PDSCONSTANT.FX_COMMODITY_NAME_VALUE) {
      configurationRequest.SecondaryCurrency = '';
      setsecondaryCurrencyRequired(false);
    }
    else {
      setsecondaryCurrencyRequired(true);
    }
    if (configurationRequest.Granularity && PDSCONSTANT.PDS_HOURLY_GRANULARITITES.includes(configurationRequest.Granularity)) {
      setlocationRequired(true);
      setProfileRequired(false);
      setprofileErrorMessage("")
    }
    else {
      setlocationRequired(false);
      setProfileRequired(true);
    }
    if ((configurationRequest.Currency !== '') && (configurationRequest.Currency === configurationRequest.SecondaryCurrency)) {
      setsecondaryCurrencyValidationErrorMessage(ValidationMessage.SAME_SECONDARY_CURRENCY_AS_CURRENCY)
      return;
    }
    setconfigurationRequest({ ...configurationRequest });
    resetValidationOnChange(configurationRequest);
  };
  if (!isEditable) {
    footer = <Grid columns={2}>
      <Grid.Cell><Styled.DrawerResetButtonStyled onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} placeholder="" size='small' onClick={ResetCurveRequest} variant="outlined">Reset</Styled.DrawerResetButtonStyled > </Grid.Cell>
      <Grid.Cell><Styled.DrawerButtonStyled onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} placeholder="" size='small' disabled={isDisabled} onClick={CreateOrEditCurve}>Create</Styled.DrawerButtonStyled><Styled.DrawerButtonStyled onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} placeholder="" size='small' variant="outlined" onClick={onClose}>Cancel</Styled.DrawerButtonStyled></Grid.Cell>
    </Grid>;
  } else {
    footer = <Grid columns={2}>
      <Grid.Cell> </Grid.Cell>
      <Grid.Cell><Styled.DrawerButtonStyled onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} placeholder="" size='small' disabled={isDisabled} onClick={CreateOrEditCurve} >Save</Styled.DrawerButtonStyled><Styled.DrawerButtonStyled onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} placeholder="" size='small' variant="outlined" onClick={onClose}>Cancel</Styled.DrawerButtonStyled></Grid.Cell>
    </Grid>;
  }
  return (
    <><Styled.DrawerStyled maskClosable={false} maskStyle={maskStyle} placement="right" open={drawerProps.open} onClose={onClose} header={headerText}>

      <Styled.SideDrawerNameTextGrid columns={1}>
        <Grid.Cell><FormField id="CurveName" label="Name" mandatory state="error" message={curveNameValidationErrorMessage}>
          <TextInput onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} crossOrigin='false' value={configurationRequest.CurveName} placeholder="" onChange={(e: any) => onTextBoxInputChange("CurveName", e.target.value)} /><span><small>{PDSCONSTANT.SUB_TEXT_CURVE_NAME}</small></span>
        </FormField></Grid.Cell>
      </Styled.SideDrawerNameTextGrid>
      <Styled.SideDrawerGrid areas={areas} columns={2}>
        <Grid.Cell><FormField id="Commodity" label="Commodity" state="error" message={commodityErrorMessage} mandatory><Dropdown masterData={commodity} id="Commodity" onDropDownChange={onInputChange} selectedData={configurationRequest.Commodity} /></FormField></Grid.Cell>
        <Grid.Cell><FormField id="CurveType" label="Curve Type" state="error" mandatory message={curveTypeValidationErrorMessage}><Dropdown masterData={curveType} id="CurveType" onDropDownChange={onInputChange} selectedData={configurationRequest.CurveType} /></FormField></Grid.Cell>
        <Grid.Cell><FormField id="Granularity" label="Granularity" state="error" message={granularityValidationErrorMessage} mandatory><Dropdown masterData={granularity} id="Granularity" onDropDownChange={onInputChange} selectedData={configurationRequest.Granularity} /></FormField></Grid.Cell>
        <Grid.Cell><FormField id="Unit" label="Unit" state="error" message={unitValidationErrorMessage} mandatory><Dropdown masterData={unitOfMeasure} id="UnitOfMeasure" onDropDownChange={onInputChange} selectedData={configurationRequest.UnitOfMeasure} /></FormField></Grid.Cell>
        <Grid.Cell><FormField id="PriceType" label="Price Type" state="error" message={priceTypeValidationErrorMessage} mandatory><Dropdown masterData={priceType} id="PriceType" onDropDownChange={onInputChange} selectedData={configurationRequest.PriceType} /></FormField></Grid.Cell>
        <Grid.Cell><FormField id="Currency" label="Currency" state="error" message={currencyValidationErrorMessage} mandatory><Dropdown masterData={currency} id="Currency" onDropDownChange={onInputChange} selectedData={configurationRequest.Currency} /></FormField></Grid.Cell>
        <Grid.Cell><FormField id="SecondaryCurrency" state="error" label="Secondary Currency" message={secondarCurrencyValidationErrorMessage} mandatory={secondaryCurrencyRequired}><Dropdown disabledDrpDwn={configurationRequest.Commodity === PDSCONSTANT.FX_COMMODITY_NAME_VALUE ? false : true} masterData={currency} id="SecondaryCurrency" onDropDownChange={onInputChange} selectedData={configurationRequest.SecondaryCurrency} /></FormField><span><small>{PDSCONSTANT.SUB_TEXT_MANDATORY_FIELD_FOR_SECONDARY_CURRENCY}</small></span></Grid.Cell>
        <Grid.Cell><FormField id="Location" state="error" label="Location" mandatory={locationRequired}><Dropdown masterData={location} id="Location" onDropDownChange={onInputChange} selectedData={configurationRequest.Location} /></FormField><span><small>{PDSCONSTANT.SUB_TEXT_MANDATORY_FIELD_FOR_HIGH_GRANULARITIES}</small></span></Grid.Cell>
        <Grid.Cell><FormField id="DeliveryPoint" label="Delivery Point"><Dropdown masterData={deliveryPoint} id="CommodityDeliveryPoint" onDropDownChange={onInputChange} selectedData={configurationRequest.CommodityDeliveryPoint} /></FormField></Grid.Cell>
        <Grid.Cell><FormField id="TimeZone" state="error" label="TimeZone" mandatory message={curveTZIdValidationErrorMessage}><Dropdown masterData={timeZone} id="TZIdentifier" onDropDownChange={onInputChange} selectedData={configurationRequest.TZIdentifier} /></FormField></Grid.Cell>
        <Grid.Cell><FormField id="Profile" label="Profile" state="error" mandatory={profileRequired} message={configurationRequest.Commodity === PDSCONSTANT.POWER_COMMODITY_NAME_VALUE ? profileErrorMessage : ""}><Dropdown disabledDrpDwn={configurationRequest.Commodity === PDSCONSTANT.POWER_COMMODITY_NAME_VALUE ? false : true} masterData={profile} id="CommodityProfile" onDropDownChange={onInputChange} selectedData={configurationRequest.CommodityProfile} /><span><small>{PDSCONSTANT.SUB_TEXT_COMMODITY_PROFILE}</small></span></FormField></Grid.Cell>

        <Grid.Cell><FormField id="ContractOption" label="Put/Call" state="error" message={configurationRequest.CurveType === PDSCONSTANT.VOLATILITY_CURVE_TYPE_VALUE ? contractOptionValidationErrorMessage : ""} mandatory><Dropdown disabledDrpDwn={configurationRequest.CurveType === PDSCONSTANT.VOLATILITY_CURVE_TYPE_VALUE ? false : true} masterData={putCall} id="ContractOption" onDropDownChange={onInputChange} selectedData={configurationRequest.ContractOption} /><span><small>{PDSCONSTANT.SUB_TEXT_CONTRACT_OPTION}</small></span></FormField></Grid.Cell>
        <Grid.Cell><FormField id="Status" label="Status" mandatory><Styled.ButtonGroupStyled id="status" size="medium" value={statusValue} onChange={handleStatusChange}>
          <ButtonGroupButton name="Enable" />
          <ButtonGroupButton name="Disable" />
        </Styled.ButtonGroupStyled ></FormField></Grid.Cell>
        <Grid.Cell><FormField id="Access" label="Access" mandatory><Styled.ButtonGroupStyled id="access" value={isPublic} size="medium" onChange={handleIsPublicChange}>
          <ButtonGroupButton name=" Public" />
          <ButtonGroupButton name=" Private" />
        </Styled.ButtonGroupStyled></FormField>
        </Grid.Cell>
        <Grid.Cell><FormField id="DataProvider" state="error" label="Data Provider" mandatory message={dataProviderErrorMessage}><Dropdown masterData={dataProvider} id="DataProvider" onDropDownChange={onInputChange} selectedData={configurationRequest.DataProvider} /></FormField></Grid.Cell>
        <Grid.Cell><FormField id="Source" label="Source" state="error" mandatory message={sourceErrorMessage}><Dropdown masterData={source} id="InterfaceApplication" onDropDownChange={onInputChange} selectedData={configurationRequest.InterfaceApplication} /></FormField></Grid.Cell>
      </Styled.SideDrawerGrid>
      <Styled.SideDrawerNameTextGrid columns={1}>
        <Grid.Cell><FormField id="SourceCurve" state="error" label="Source System Curve ID" mandatory message={sourceCurveErrorMessage}><TextInput onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} crossOrigin='false' value={configurationRequest.SourceCurveName} onChange={(e: any) => onTextBoxInputChange("SourceCurveName", e.target.value)} placeholder="" /><span><small>{PDSCONSTANT.SUB_TEXT_SOURCE_CURVE_NAME}</small></span></FormField></Grid.Cell>
      </Styled.SideDrawerNameTextGrid>
      <Styled.SideDrawerNameTextGrid columns={1}>
        <Grid.Cell><FormField id="Notes" label="Notes" >  <TextArea onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} maxLength={4000} size="large" value={configurationRequest.Notes} placeholder="" onChange={(e: any) => onTextBoxInputChange("Notes", e.target.value)} /></FormField></Grid.Cell>
      </Styled.SideDrawerNameTextGrid>
      <hr />
      {/*render footer section -show create/save button, based on permission */}
      {permissions.CanWrite_CurveMasterData() && footer}
      <ToastContainer />
    </Styled.DrawerStyled></>
  );
}
