import { ButtonLink, Checkbox, Grid, Modal, useSorter, SingleDatePicker, Tooltip, FormField, SinglePickerValueType, TextInput } from '@sede-x/shell-ds-react-framework';
import { Add, ArrowUpSquare, Search } from '@sede-x/shell-ds-react-framework/build/cjs/components/Icon/components';
import { ColumnsType, RecordType } from '@sede-x/shell-ds-react-framework/build/cjs/components/Table/Table.types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { TABLE_CLASS } from '@sede-x/shell-ds-react-framework/build/cjs/utils/constants';
import * as Styled from './ManualAligneExport.style';
import DataTableWithScrollBar from '../../library/Table/TableWithScrollBar';
import DataTableWithVerticalScrollBar from '../../library/Table/TableWithVerticalScrollBar';
import DataTablePagination from '../../library/Pagination/Pagination';
import { FilterContainer, FilterStyled, FlagImgStyled, InCompleteCurveExportWarningStyled } from './ManualAligneExport.style';
import { IAligneCurvePayload } from '../../interfaces/IAligneCurvePayload';
import { IPaginationFilter } from '../../interfaces/IPaginationFilter';
import { ISortCriteria } from '../../interfaces/ISortCriteria';
import { ProductCurves } from '../../services/ProductCurves';
import { ManualAligneCurveExport } from '../../services/ManualAligneCurveExport';
import { IManualAligneExportCurveData } from '../../interfaces/IManualAligneExportCurveData';
import { PDSCONSTANT } from '../../common/constants';
import { SortDirection, BtnGrpEnableDisableStatus, BtnGrpPublicPrivateStatus } from '../../common/enum';
import { ManualAligneExportColumnsHelper } from './ManualAligneExportColumnsHelper';
import { ManualAligneExportPopupColumnsHelper } from './ManualAligneExportPopupColumnsHelper';
import { IManualAligneExportColumnProps } from '../../interfaces/IManualAligneExportColumnProps';
import { IManualAligneExportPopupColumnProps } from '../../interfaces/IManualAligneExportPopupColumnProps';
import { IExportCurve } from '../../interfaces/IExportCurve';
import { MasterData } from '../../services/MasterData';
import { IAligneCurveFilter } from '../../interfaces/IAligneCurveFilter';
import Loader from '../../library/Loader/Loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IAligneCurveExportFilter } from '../../interfaces/IAligneCurveExportFilter';
import dayjs, { Dayjs } from 'dayjs';
import { IProductCurveFilter } from '../../interfaces/IProductCurveFilter';
import { UserPermission } from '../../services/UserPermission';
import { ValidationMessage } from '../../common/validationHelper';
import { ICurveViewerProductCurveData } from '../../interfaces/ICurveViewerProductCurveData';
import CurveViewerDrawer from '../CurveViewer/CurveViewerSideDrawer';
import { DownloadHelper } from '../../common/DownloadHelper';
import { IDownloadFilter } from '../../interfaces/IDownloadFilter';

// Image Url for Confirmation
const imgUrl = process.env.REACT_APP_HOME_URL + "/Icon.png";
// Image URL for Incomplete curve redflag
const redFlagImgUrl = process.env.REACT_APP_HOME_URL + "/redflagIcon.png";
// Popup table 
let popupTable: any;
// date picker 
let datePicker: any;
// checkbox for market correction
let checkboxMarketCorrection: any;
// side drawer
let drawer: any;
let inCompleteCurveExportWarning: any;
// Add 0 if number is lesser than 10
const pad = (num: number) => {
  return (num < 10 ? '0' : '') + num;
};
// Method to change date into ISO format string
const toIsoString = (date: Date) => {
  return date.getFullYear() +
    '-' + pad(date.getMonth() + 1) +
    '-' + pad(date.getDate()) +
    'T00:00:00Z'
};
var effectiveStartDate = new Date(new Date().setDate(new Date().getDate() - 1));
// Setting effective start Date hours, minutes and milliseconds to Zero
effectiveStartDate.setUTCHours(0, 0, 0, 0);
// Setting Default Effective End Date
var effectiveEndDate = new Date();
// Setting effective End Date hours, minutes and milliseconds to Zero
effectiveEndDate.setUTCHours(0, 0, 0, 0);
// Popup ShowHeader and ShowFooter props
const args = {
  showHeader: false,
  showFooter: false,
};
// For rendering source date value in table
const renderSourceDateAndTimeValue = (value: any, record: any, index: number) => {
  if (dayjs(value).isValid()) {
    value = dayjs(value).utc().format(PDSCONSTANT.DATE_FORMAT) + " | " + dayjs(value).utc().format(PDSCONSTANT.TIME_FORMAT);
  }
  else {
    value = '';
  }
  return (<div>{value}</div>)
};
// For rendering string value in table
const renderValue = (value: any, record: any, index: number) => {
  return (<div>{value}</div>)
};
// For rendering date value in table
const renderDateValue = (value: any, record: any, index: number) => {
  value = dayjs(value).format(PDSCONSTANT.DATE_FORMAT);
  return (<div>{value}</div>)
};
// React screen for ManualAligneExport
export const ManualAligneExport = () => {
  const permissions = UserPermission();
  // React state variables  
  const defaultPageSize = PDSCONSTANT.DEFAULT_PAGE_SIZE;
  const [currentPage, setPage] = useState(1);
  const [curveConfigurationData, setCurveConfigurationData] = useState<IManualAligneExportCurveData[]>([]);
  const [isProcessing, setIsProcessing] = useState(false);
  //used for maintain and send the selectAll Status to backend api
  const [headerCheckbox, setHeaderCheckbox] = useState(false);
  //use to maintain the header checkbox check/uncheck status at UI only, no role in backend selectAll Status
  const [headerCheckboxUI, setHeaderCheckboxUI] = useState(false);
  let [unSelectedData, setUnSelectedData] = useState<IManualAligneExportCurveData[]>([]);
  const [ToolTipClassName, setToolTipClassName] = useState('');
  const [datapickerValidationErrorMessage, setdatapickerValidationErrorMessage] = useState('');
  const [maturityDate, setmaturityDate] = useState<Date | null>(null);
  const [AddToMarketExport, setAddToMarketExport] = useState(false);
  const [EditMaturityExport, setEditMaturityExport] = useState(false);
  const [PopupTitle, setPopupTitle] = useState('');
  const [AddToMarketButtonDisable, setAddToMarketButtonDisable] = useState(true);
  const [PriceCorrectionButtonDisable, setPriceCorrectionButtonDisable] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [exportCurveConfigurationData, setExportCurveConfigurationData] = useState<IManualAligneExportCurveData[]>([]);
  const [manualAlignePopupData, setmanualALignePopupData] = useState<IManualAligneExportCurveData[]>([]);
  const [isScrollableTable, setIsScrollableTable] = useState(true);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const { DownloadSelectedProductCurveData } = ProductCurves();
  const { GetManualExportFilters } = MasterData();
  const { GetAligneExportCurves, ExportSelectedProductCurveData, GetAligneExportCurvesForPopup } = ManualAligneCurveExport();
  const [wildCardSearchText, setWildCardSearchText] = useState('');
  const [productCurvefilterCriteria, setProductCurveFilterCriteria] = useState<IProductCurveFilter>({ Name: null, CurveType: [], Granularity: [], PriceType: [], Profile: [], DeliveryPoint: [], Currency: [], Unit: [], Location: [], ContractOption: [], Commodity: [], DataProvider: [], Source: [], IsPrivate: null, IsActive: true, SourceCurveName: null, WildCardSearchText: null, EffectiveStartDate: effectiveStartDate.toISOString(), EffectiveEndDate: effectiveEndDate.toISOString() });
  const [filterCriteria, setFilterCriteria] = useState<IAligneCurveFilter>({ Name: null, CurveType: [], Granularity: [], Component: [], ContractOption: [], ExportSchedule: [], ImportType: [], Settle: null, Market: [], PSET: [], SecondaryComponent: [], SecondaryMarket: [], Unit: [], ZeroCal: [], AddToMarket: [], IsActive: true, WildCardSearchText: null, EffectiveStartDate: effectiveStartDate.toISOString(), EffectiveEndDate: effectiveEndDate.toISOString(), ExportStartDateTime: null, ExportEndDateTime: null, Status: [], SingleDayCurve: false });
  const [paginationFilter, setPaginationFilter] = useState<IPaginationFilter>({ PageNumber: 1, PageSize: defaultPageSize, TotalRecords: 0 });
  const [sortCriteria, setSortCriteria] = useState<ISortCriteria[]>([]);
  const aligneCurvePayload: IAligneCurvePayload = { WildCardSearchText: wildCardSearchText, FilterCriteria: filterCriteria, PaginationFilter: paginationFilter, SortCriteria: sortCriteria }
  let [selectedData, setSelectedData] = useState<IManualAligneExportCurveData[]>([]);
  const [manualExportFiletr, setmanualExportFilter] = useState<any>();
  const [downloadFilter, setDownloadFilter] = useState<IDownloadFilter>({ IsAllSelected: false, SelectedCurveList: [], UnSelectedCurveList: [], ProductCurvesFilter: { Name: null, CurveType: [], Granularity: [], PriceType: [], Profile: [], DeliveryPoint: [], Currency: [], Unit: [], Location: [], ContractOption: [], Commodity: [], DataProvider: [], Source: [], IsPrivate: null, IsActive: true, SourceCurveName: null, WildCardSearchText: null, EffectiveEndDate: null, EffectiveStartDate: null } });
  const [defaultDate] = useState(new Date());
  const [isCheckedMarketCorrection, setIsCheckedMarketCorrection] = useState(false);
  const [productCurvePriceData, setProductCurvePriceData] =
    useState<ICurveViewerProductCurveData>({
      CurveName: "",
      CurveType: "",
      EffectiveDate: "",
      Granularity: "",
      UpdatedDate: "",
      SourceUpdatedDate: "",
      CurveBuildId: "",
      TraderApprovalDateTimeUTC: "",
      TraderApprovalName: "",
      TraderApprovalIndicator: false,
    });

  const exportFilter: IAligneCurveExportFilter = { AligneCurvesFilter: filterCriteria, IsAllSelected: headerCheckbox, ExportedCurveList: selectedData, ExportedBy: '', MaturityDate: null, AddToMarketExport: null, MarketCorrection: false }
  useEffect(() => {
    // Set Page Size on the basis of  Window size 
    paginationFilter.PageSize = window.innerHeight ? parseInt((window.innerHeight / 70).toFixed()) : 10;
    // Fetch the Manual ALigne Export Curve data on load and on change of aligneCurvePayload 
    getManualExportCurveData();
  }, [wildCardSearchText, filterCriteria, paginationFilter, sortCriteria]);
  //On load manual export filters on load
  useEffect(() => {
    getManualExportFilters();
  }, []);
  const onCloseConfirmationModal = () => { setOpenConfirmationModal(false); }
  const onCloseModal = () => { setOpenModal(false); }
  // Api calls for Manual Export Curve data
  const getManualExportCurveData = () => {
    // Comment for Loader - To be built    
    setCurveConfigurationData([]);
    setmanualALignePopupData([]);
    setIsProcessing(true);
    GetAligneExportCurves(aligneCurvePayload).then(result => {
      //maintain the checked status of current page on pagination
      const currentPageData: any = result.data.Data;
      currentPageData.map((curve: any) => {
        //handle checkbox selection on pagination  
        if (headerCheckbox) {

          //handle unchecked list -> marked them all unchecked
          const uncheckMe = unSelectedData.some(function (unselectedCurve: IManualAligneExportCurveData) {
            curve.IsChecked = unselectedCurve.IsChecked;
            const IsCurveEqual: boolean = JSON.stringify(unselectedCurve) === JSON.stringify(curve);
            return IsCurveEqual;
          });
          curve.IsChecked = !uncheckMe && headerCheckbox;
        }
        else {
          //handle checked list - marked them all checked  
          const checkedMe = selectedData.some(function (selectedCurve: IManualAligneExportCurveData) {
            curve.IsChecked = selectedCurve.IsChecked;
            const IsCurveEqual: boolean = JSON.stringify(selectedCurve) === JSON.stringify(curve);
            return IsCurveEqual;
          });
          curve.IsChecked = checkedMe;
        }

        return curve;
      });
      //set the grid data
      setCurveConfigurationData(currentPageData);
      setIsProcessing(false);
      paginationFilter.TotalRecords = result.data.PaginationFilter.TotalRecords;
      paginationFilter.PageNumber = currentPage;
      paginationFilter.PageSize = result.data.PaginationFilte.PageSize;
    }).catch((err) => { setIsProcessing(false); });
  }
  // Method for Manual Export filters load
  const getManualExportFilters = () => { GetManualExportFilters().then(result => { setmanualExportFilter(result.data) }); }
  //Confirmation dialog settings for add to market flow to Aligne
  const AddToMarketExportConfirmDialog = () => {
    setmaturityDate(null);
    setdatapickerValidationErrorMessage('');
    setAddToMarketExport(true);
    setPopupTitle('Add To Market export to Aligne');
    ExportAligneDialog();
  };
  //Confirmation dialog settings for Edit Maturity Date to Aligne
  const EditMaturityDateExportConfirmDialog = () => {
    setdatapickerValidationErrorMessage('');
    setAddToMarketExport(false);
    setEditMaturityExport(true);
    setmaturityDate(defaultDate);
    setPopupTitle('Price Correction Export to Aligne');
    ExportAligneDialog();
  };

  const ExportAligneDialog = () => {

    if (!exportFilter.IsAllSelected) {
      var result = selectedData.filter((obj) => { return obj.IsChecked === true; });
      setExportCurveConfigurationData(result);
      setmanualALignePopupData(result);
      if (result.length > 6) { setIsScrollableTable(true); }
      else { setIsScrollableTable(false); }
      setOpenModal(true);
    }
    else {
      GetAligneExportCurvesForPopup(aligneCurvePayload).then(result => {
        let allCurveData: IManualAligneExportCurveData[] = result.data.Data;
        //remove the unselected list from all data
        if (unSelectedData.length > 0 && allCurveData.length > 0) {
          // Fetching only the selected curve and filter out the unselected curve
          allCurveData = allCurveData.filter((curve: IManualAligneExportCurveData) => !unSelectedData.find(function (unselectedCurve: IManualAligneExportCurveData) {
            curve.IsChecked = unselectedCurve.IsChecked;
            const IsCurveEqual: boolean = JSON.stringify(unselectedCurve) === JSON.stringify(curve);
            return IsCurveEqual;
          }));
        }
        allCurveData.forEach((curve: IManualAligneExportCurveData) => { curve.IsChecked = true; return curve; });
        setExportCurveConfigurationData(allCurveData);
        setmanualALignePopupData(allCurveData);
        if (allCurveData.length > 6) { setIsScrollableTable(true); }
        else { setIsScrollableTable(false); }
        setOpenModal(true);
      });
    }
  }
  // Confirmation dialog  settings for normal flow to Aligne
  const ExportConfirmDialog = () => {
    setAddToMarketExport(false);
    setEditMaturityExport(false);
    setPopupTitle('Export to Aligne');
    ExportAligneDialog();
  }
  const ExportSelectedCurveData = () => {
    try {
      exportFilter.ExportedCurveList = exportCurveConfigurationData.filter((obj) => { return obj.IsChecked === true; }).map(({ CurveName, EffectiveDate, UpdatedDate, SourceUpdatedDate, Market, Component, PSET, SecondaryMarket, SecondaryComponent, ExportSchedule, Unit, ZeroCal, AligneImportType, ContractOption, Settle, CurveBuildId, AddToMarket, Granularity }) =>
        ({ CurveName, EffectiveDate, UpdatedDate, SourceUpdatedDate, Market, Component, PSET, SecondaryMarket, SecondaryComponent, ExportSchedule, Unit, ZeroCal, AligneImportType, ContractOption, Settle, CurveBuildId, AddToMarket, Granularity }));
      //show error message if no curve is selected to download
      if (exportFilter.ExportedCurveList.length === 0) { toast.error("No curve selected to export!", { position: toast.POSITION.TOP_CENTER }); return; }
      //if add to market export is true, then set the AddToMarketExport field in the filter to true to allow market flow to Aligne
      if (AddToMarketExport == true) {
        if ((maturityDate === undefined || maturityDate == null)) {
          setdatapickerValidationErrorMessage(ValidationMessage.MATURITY_DATE_REQUIRED);
          return false;
        }
        else {
          setdatapickerValidationErrorMessage('');
          exportFilter.AddToMarketExport = true;
          exportFilter.MaturityDate = maturityDate;
          exportFilter.MarketCorrection = null;
        }
        ExportProductCurveData(exportFilter);
      }
      else if (EditMaturityExport == true) {
        if (maturityDate === undefined || maturityDate == null) {
          setdatapickerValidationErrorMessage(ValidationMessage.MATURITY_DATE_REQUIRED);
          return false;
        }
        else {
          setdatapickerValidationErrorMessage('');
          exportFilter.MaturityDate = maturityDate;
        }
        exportFilter.AddToMarketExport = false;
        exportFilter.MarketCorrection = null;
        ExportProductCurveData(exportFilter);
        if (isCheckedMarketCorrection == true) {
          exportFilter.MarketCorrection = true;
          ExportProductCurveData(exportFilter);
        }
      }
      else {
        exportFilter.MaturityDate = null;
        exportFilter.AddToMarketExport = false;
        exportFilter.MarketCorrection = null;
        ExportProductCurveData(exportFilter);
      }


      setIsCheckedMarketCorrection(false);
      setOpenModal(false);
      setIsProcessing(true);
    }
    catch (error) {
      // In case of error set is processing to false
      setIsProcessing(false);
      // Show toaster message for error
      toast.error("Manual Aligne Export process failed.", { position: toast.POSITION.TOP_CENTER });
    }
  }
  const ExportProductCurveData = (exportFilter: IAligneCurveExportFilter) => {
    // API call for Manual Aligne Export
    ExportSelectedProductCurveData(exportFilter).then(_result => {
      // In case of success set is processing to false 
      setIsProcessing(false);
      // Open confirmation dialog for successful manual aligne export process
      setOpenConfirmationModal(true);
    })
      .catch((err) => {
        // In case of error set is processing to false
        setIsProcessing(false);
        // Show toaster message for error
        toast.error(err.response.data, { position: toast.POSITION.TOP_CENTER });
      });
  }
  // Call this method on click of Apply in filters
  const handleFilterCriteria = (filterValues: any) => {
    resetPageNumber();
    filterCriteria.Market = filterValues['market'];
    filterCriteria.Component = filterValues['component'];
    filterCriteria.PSET = filterValues['pset'];
    filterCriteria.Name = filterValues['name'];
    filterCriteria.CurveType = filterValues['curveType'];
    filterCriteria.Granularity = filterValues['granularity'];
    filterCriteria.AddToMarket = filterValues['addToMarket'];
    filterCriteria.ExportSchedule = filterValues['exportSchedule'];
    filterCriteria.SingleDayCurve = null; //set the default value
    if (filterValues['priceCorrection'] !== null && filterValues['priceCorrection'] !== undefined && filterValues['priceCorrection'] == 'TRUE') {
      filterCriteria.SingleDayCurve = true;
    }

    //Setting the Product Curve filter
    productCurvefilterCriteria.Granularity = filterValues['granularity'];
    productCurvefilterCriteria.CurveType = filterValues['curveType'];
    filterCriteria.Settle = filterValues['Settle'];
    if (filterValues['effectivedaterange'] !== undefined && filterValues['effectivedaterange'] !== null) {
      filterCriteria.EffectiveEndDate = toIsoString(filterValues['effectivedaterange'][1]['$d']);
      filterCriteria.EffectiveStartDate = toIsoString(filterValues['effectivedaterange'][0]['$d']);
      productCurvefilterCriteria.EffectiveEndDate = toIsoString(filterValues['effectivedaterange'][1]['$d']);
      productCurvefilterCriteria.EffectiveStartDate = toIsoString(filterValues['effectivedaterange'][0]['$d']);
    }
    else { filterCriteria.EffectiveEndDate = null; filterCriteria.EffectiveStartDate = null; productCurvefilterCriteria.EffectiveEndDate = null; productCurvefilterCriteria.EffectiveStartDate = null; }
    // Assign state variable value to new variable to update with new state    
    let manualAligneExportFilterCriteria: IAligneCurveFilter = filterCriteria;
    setFilterCriteria(manualAligneExportFilterCriteria);
    // Assign state variable value to new variable to update with new state    
    let manualAlignePopUpExportFilterCriteria: IProductCurveFilter = productCurvefilterCriteria;
    setProductCurveFilterCriteria(manualAlignePopUpExportFilterCriteria);
    //enable Add To Market Export button only if Add to Market filter is applied
    //show tooltip on button only if disabled
    if (filterValues['addToMarket'] == 'TRUE') {
      setAddToMarketButtonDisable(false);
      setToolTipClassName('hidden');
    }
    else {
      setAddToMarketButtonDisable(true);
      setToolTipClassName('');
    }
    //enable Price Correction Export button only if Price Correction filter is applied
    //show tooltip on button only if disabled
    if (filterValues['priceCorrection'] == 'TRUE') {
      setPriceCorrectionButtonDisable(false);
      setToolTipClassName('hidden');
    }
    else {
      setPriceCorrectionButtonDisable(true);
      setToolTipClassName('');
    }
    //clear previous checked status on filter search
    resetCheckboxSelection();
  }
  //handle date changes for Add to Market export to aligne. Setting Maturity Date
  const HandleOnDateChange = (e: any) => {
    setmaturityDate(e);
  }
  //handle checked/unchecked values for Add to Market Correction field
  const handleMarketCorrectionCheckBoxChange = (e: any) => {
    setIsCheckedMarketCorrection(!isCheckedMarketCorrection);
  }
  // On Page Change updated the Pagination filter
  const handlePaginationFilter = (paginationData: IPaginationFilter) => { setPaginationFilter(paginationData); }
  // handle child checkbox selection when header is checked
  const handleChildSelectionWhenHeaderIsChecked = (currentSelection: IManualAligneExportCurveData) => {
    if (!currentSelection.IsChecked) {
      unSelectedData.push(currentSelection);
      //also uncheck the global UI checkbox
      setHeaderCheckboxUI(false);
    }
    else {
      // Flter the selected curve from unselected curve 
      setUnSelectedData(unSelectedData.filter(function (item) {
        const unselectCurve = JSON.parse(JSON.stringify(item));;
        unselectCurve.IsChecked = currentSelection.IsChecked;
        const IsCurveEqual: boolean = JSON.stringify(unselectCurve) === JSON.stringify(currentSelection);
        return !IsCurveEqual;

      }));

      //checked header check box checked, if no unchecked
      if (unSelectedData.length === 0) {
        setHeaderCheckboxUI(true);
      }
    }
    //clear the selected list    
    setSelectedData([]);
  };

  // For rendering checkbox in table  
  const renderCheckbox = (value: any, record: any, index: number) => {
    //Handle the changes on check or uncheck of a particular row checkbox
    const handleChange = () => {
      // Update the particular row checked/unchecked status 
      const selectCurveData = [
        ...curveConfigurationData.slice(0, index),
        {
          ...curveConfigurationData[index],
          IsChecked: !curveConfigurationData[index].IsChecked,
        },
        ...curveConfigurationData.slice(index + 1),
      ];
      // Set the updated curve status 
      setCurveConfigurationData(selectCurveData);
      const currentSelection = selectCurveData[index];
      //handle checkbox selection when header is checked/unchecked
      if (headerCheckbox) {
        //handle child checkbox selection when header checkbox is unchecked   
        //re-factored separate method to reduce congnative complexity - sonar issue fix
        handleChildSelectionWhenHeaderIsChecked(currentSelection);
      }
      else {
        //handle when header checkbox is unchecked

        if (currentSelection.IsChecked) {
          selectedData.push(currentSelection);
        }
        else {
          selectedData = selectedData.filter(function (item) {
            return item.CurveName !== currentSelection.CurveName
          });
          //to fix issue reported in SonarQube - Change the argument of this setter to not use its matching state variable
          var dataToset = selectedData;
          setSelectedData(dataToset);
          setHeaderCheckboxUI(false);
        }

        //if all item selected -> checked header checkbox
        if (selectedData.length === paginationFilter.TotalRecords && unSelectedData.length === 0) {
          setHeaderCheckboxUI(true);
        }
        //clear the selected list          
        setUnSelectedData([]);
      }

    };
    // Rendering the checkbox for row            
    return <Checkbox crossOrigin='false' label="" size='small' id={index.toString()} checked={value} onChange={handleChange} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />;
  };
  const renderPopupCheckbox = (value: any, record: any, index: number) => {
    //Handle the changes on check or uncheck of a particular row checkbox
    const handleChange = () => {
      // Update the particular row checked/unchecked status 
      const selectPopupCurveData = [
        ...exportCurveConfigurationData.slice(0, index),
        {
          ...exportCurveConfigurationData[index],
          IsChecked: !exportCurveConfigurationData[index].IsChecked,
        },
        ...exportCurveConfigurationData.slice(index + 1),
      ];
      // Set the updated curve status
      for (let i = 0; i < curveConfigurationData.length; i++) {
        for (let j = 0; j < selectPopupCurveData.length; j++) {
          if (curveConfigurationData[i]._id === selectPopupCurveData[j]._id) { curveConfigurationData[i].IsChecked = selectPopupCurveData[j].IsChecked; }
        }
      }
      setExportCurveConfigurationData(selectPopupCurveData);
      // Assign state variable value to new variable to update with new state    
      let manualAligneExportCurveData: IManualAligneExportCurveData[] = curveConfigurationData;
      // Set the updated curve status 
      setCurveConfigurationData(manualAligneExportCurveData);
      setmanualALignePopupData(selectPopupCurveData);


      //maintain the global list
      const exportCurveData = exportCurveConfigurationData.filter((obj) => { return obj.IsChecked === true; })
      setSelectedData(exportCurveData);
    };
    // Rendering the checkbox for row            
    return <Checkbox crossOrigin='false' label="" size='small' id={index.toString()} checked={value} onChange={handleChange} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />;
  };

  // For rendering link in table
  const renderCompletionFlow = (value: any, record: any, index: number) => {

    value = (value === false ? <FlagImgStyled src={redFlagImgUrl} title={PDSCONSTANT.TOOLTIP_TEXT_IS_CURVE_COMPLETE}></FlagImgStyled> : "");
    return (<div>{value}</div>)
  };

  // reset checkbox selections
  const resetCheckboxSelection = () => { setSelectedData([]); setHeaderCheckbox(false); };
  // For rendering check box all functionality 
  const handleCheckboxAll = () => {
    // In case of clicking on select/unselect all check box render the curve data again    
    const checkedCurveData = curveConfigurationData.map((item) => ({ ...item, IsChecked: !headerCheckbox }));
    // Setting the curve data again
    setCurveConfigurationData(checkedCurveData);
    //Checked Exported curve data
    setExportCurveConfigurationData(checkedCurveData);
    //clear checkbox selection list as all is selected
    setUnSelectedData([]);
    if (headerCheckbox) //if header checkbox is not selected, then clear selected data
      setSelectedData([]);
    // Setting the header checkbox on click 
    setHeaderCheckbox(!headerCheckbox);
    setHeaderCheckboxUI(!headerCheckbox);
  };

  // For Right Overlay state variable for Drawer component open state
  const [open, setOpen] = useState(false);
  const onClose = () => {
    setOpen(false);
  };
  // For rendering link in table
  const renderLinkValue = (value: any, record: any, index: number) => {
    return (
      <ButtonLink
        onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}
        placeholder=""
        size="small"
        onClick={() => {
          var productCurveData = curveConfigurationData[index];
          var filter: IDownloadFilter = {
            SelectedCurveList: [curveConfigurationData[index]._id], IsAllSelected: false, UnSelectedCurveList: [],
            ProductCurvesFilter: { Name: null, CurveType: [], Granularity: [], PriceType: [], Profile: [], DeliveryPoint: [], Currency: [], Unit: [], Location: [], ContractOption: [], Commodity: [], DataProvider: [], Source: [], IsPrivate: null, IsActive: true, SourceCurveName: null, WildCardSearchText: null, EffectiveEndDate: null, EffectiveStartDate: null }
          };
          //set selected curve
          setDownloadFilter(filter);

          //load price data
          setProductCurvePriceData(productCurveData);
          setOpen(true);
        }}
      >
        {value}
      </ButtonLink>
    );
  };

  //Set the Page Number to 1 
  const resetPageNumber = () => {
    setPage(1);
    setPaginationFilter({ PageNumber: 1, PageSize: defaultPageSize, TotalRecords: 0 });
  }
  // Passing render methods as a props in ManualligneExportColumnHelper
  const manualAligneExportColumnProps: IManualAligneExportColumnProps = { renderCheckbox: renderCheckbox, renderValue: renderValue, handleCheckboxAll: handleCheckboxAll, headerCheckbox: headerCheckboxUI, renderDateValue: renderDateValue, renderSourceDateAndTimeValue: renderSourceDateAndTimeValue, renderCompletionFlow: renderCompletionFlow, renderLinkValue: renderLinkValue };
  const aligneExportColumns = ManualAligneExportColumnsHelper(manualAligneExportColumnProps);
  // Passing render methods as a props in ManualligneExportColumnHelper
  const manualAligneExportPopupColumnProps: IManualAligneExportPopupColumnProps = { renderCheckbox: renderPopupCheckbox, renderValue: renderValue, handlePopupCheckboxAll: handleCheckboxAll, headerPopupCheckbox: headerCheckboxUI, renderDateValue: renderDateValue, renderSourceDateAndTimeValue: renderSourceDateAndTimeValue, renderCompletionFlow };
  const aligneExportPopUpColumns = ManualAligneExportPopupColumnsHelper(manualAligneExportPopupColumnProps);
  // For Sorting 
  const [transformSorterColumns, sortStates] = useSorter<RecordType>({ prefixCls: TABLE_CLASS, columns: aligneExportColumns });
  // For Sorting 
  const [transformSorterPopupColumns, sortPopupStates] = useSorter<RecordType>({ prefixCls: TABLE_CLASS, columns: aligneExportPopUpColumns });
  useMemo(() => {
    const SortCriteriaResult: ISortCriteria[] = [];
    for (var sortNumber in sortStates) {
      if (Object.hasOwn(sortStates, sortNumber)) {
        const SortCriterion: ISortCriteria = { ColumnName: '', Direction: '' };
        if (sortStates[sortNumber].sortOrder !== undefined) {
          SortCriterion.ColumnName = sortStates[sortNumber].column.dataIndex?.toString() ?? "";
          SortCriterion.Direction = sortStates[sortNumber].sortOrder === 'ascend' ? SortDirection[SortDirection.DESC] : SortDirection[SortDirection.ASC];
          SortCriteriaResult.push(SortCriterion);
        }
      }
    }
    setSortCriteria(SortCriteriaResult);
  }, [sortStates]);
  // For Popup Sorting
  useMemo(() => {
  }, [sortPopupStates]);
  // For changing the header sort icons
  const transformColumns = useCallback((innerColumns: ColumnsType<RecordType>): ColumnsType<RecordType> => transformSorterColumns(innerColumns), [transformSorterColumns]);
  // Passing the columns 
  const transformedColumns = transformColumns(aligneExportColumns);
  // For changing the header sort icons
  const transformPopupColumns = useCallback((innerColumns: ColumnsType<RecordType>): ColumnsType<RecordType> => transformSorterPopupColumns(innerColumns), [transformSorterPopupColumns]);
  // Passing the columns 
  const transformedPopupColumns = transformPopupColumns(aligneExportPopUpColumns);


  //if add to market button is clicked, then display confirmation popup with datepicker, else do not show popup  
  if (AddToMarketExport) {
    datePicker = <div className='margin' >
      <FormField id="datePicker" label="Maturity Date" state="error" message={datapickerValidationErrorMessage} mandatory>
        <SingleDatePicker onChange={HandleOnDateChange} picker='date' showToday={true} placeholder={PDSCONSTANT.ADD_TO_MARKET_DATEPICKER_PLACEHOLDER} ></SingleDatePicker>
      </FormField>
    </div>
    checkboxMarketCorrection = ''
  }
  else if (EditMaturityExport) {
    datePicker = <div className='margin' >
      <FormField id="datePicker" label="Maturity Date" state="error" message={datapickerValidationErrorMessage} mandatory>
        <TextInput value={defaultDate.toDateString()} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} crossOrigin='false' placeholder=""></TextInput>
      </FormField>
    </div>
    checkboxMarketCorrection = <div className='margin'>
      <FormField id="checkboxMarketCorrection" label="Add to Market Correction">
        <Checkbox checked={isCheckedMarketCorrection} crossOrigin='false' size='medium' onChange={handleMarketCorrectionCheckBoxChange} title='Add to Market Correction?' value="checkbox" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} ></Checkbox>
      </FormField>
    </div>
  }
  else {
    datePicker = '';
    checkboxMarketCorrection = '';
  }

  if (exportCurveConfigurationData.filter((obj) => { return obj.IsChecked === true && obj.IsComplete === false; }).length > 0) {
    inCompleteCurveExportWarning = <InCompleteCurveExportWarningStyled>{PDSCONSTANT.EXPORT_INCOMPLETE_CURVES_TO_ALIGNE}</InCompleteCurveExportWarningStyled>
  }
  else {
    inCompleteCurveExportWarning = '';
  }

  if (isScrollableTable) { popupTable = <DataTableWithVerticalScrollBar data={manualAlignePopupData} columns={transformedPopupColumns} rowKey={PDSCONSTANT.CURVE_CONFIGURATION_TABLE_ROW_KEY} /> }
  else { popupTable = <DataTableWithScrollBar data={manualAlignePopupData} columns={transformedPopupColumns} rowKey={PDSCONSTANT.CURVE_CONFIGURATION_TABLE_ROW_KEY} /> }

  // Method for Download product Curve data
  const downloadSelectedCurveData = () => {

    // API calls to download selected product curve with price data
    DownloadSelectedProductCurveData(downloadFilter).then((result) => {
      //call to helper to download
      DownloadHelper().ExportToCSV(
        PDSCONSTANT.EXPORT_FILENAME_PRODUCTCURVE,
        result.data
      );
    });
  };

  if (productCurvePriceData.CurveName !== "") {
    drawer = (
      <CurveViewerDrawer
        onClose={onClose}
        open={open}
        downloadSelectedCurveData={downloadSelectedCurveData}
        {...productCurvePriceData}
      />
    );
  }

  return (<> {isProcessing && <Loader />}
    {drawer}
    <Styled.InnerContainer><Styled.Sidebar><FilterContainer><FilterStyled onApply={handleFilterCriteria} masterData={manualExportFiletr}></FilterStyled></FilterContainer></Styled.Sidebar>
      <Styled.ContentContainer><Styled.CenteredContainer>
        <Styled.SideGrid>
          {permissions.CanExport_AligneExport_MANUAL() &&
            <Styled.GridStyled columns={2}>
              <Grid.Cell><b>Manual Aligne Export</b></Grid.Cell>
              <Grid.Cell>
                <Styled.FlexboxStyled gap='24px;'>
                  {/* displace tool tip only when button is disabled */}
                  <Tooltip onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} placeholder='' placement="topLeft" overlay={PDSCONSTANT.TOOLTIP_TEXT_ADD_TO_MARKET_BTN} className={ToolTipClassName} >
                    <span>
                      {/* to enable trigger on user interactions like hover over disabled buttons, setting pointerEvents to none */}
                      <Styled.ButtonStyled onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} placeholder='' size='small' onClick={AddToMarketExportConfirmDialog} disabled={AddToMarketButtonDisable}
                        style={AddToMarketButtonDisable ? { pointerEvents: 'none' } : {}} icon={<ArrowUpSquare />}>
                        Add to Market Export
                      </Styled.ButtonStyled>
                    </span>
                  </Tooltip>
                  <Styled.ButtonStyled onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} placeholder='' size='small' onClick={ExportConfirmDialog} icon={<ArrowUpSquare />}>Export to Aligne</Styled.ButtonStyled>
                  <Styled.ButtonStyled onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} placeholder='' size='small' onClick={EditMaturityDateExportConfirmDialog} disabled={PriceCorrectionButtonDisable}
                    style={PriceCorrectionButtonDisable ? { pointerEvents: 'none' } : {}} icon={<ArrowUpSquare />}>Price Correction Export</Styled.ButtonStyled>
                </Styled.FlexboxStyled>
              </Grid.Cell>
            </Styled.GridStyled>
          }
          <Grid columns={1}><Grid.Cell> <hr /></Grid.Cell></Grid>
          <Styled.GridStyled columns={2} ><Grid.Cell ><Styled.TextInputStyled onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} crossOrigin='false' size='small' icon={<Search height={24} />} placeholder="Search" onKeyUp={(e: any) => {
            // Fire event on enter press
            if (e.keyCode === 13) {
              resetPageNumber(); setPage(1); paginationFilter.PageNumber = 1;
              // Setting the value enetered in search textbox
              filterCriteria.WildCardSearchText = e.target.value;
              productCurvefilterCriteria.WildCardSearchText = e.target.value;
              setWildCardSearchText(filterCriteria.WildCardSearchText ?? "");
              // Assign state variable value to new variable to update with new state    
              let manualAligneExportFilterCriteria: IAligneCurveFilter = filterCriteria;
              // Set the filtercriteria with the updated text value
              setFilterCriteria(manualAligneExportFilterCriteria);
              // Calling the GetProductCurveConfigurationData method for fetching
              getManualExportCurveData();
            }
          }} />
          </Grid.Cell><Grid.Cell ></Grid.Cell>
          </Styled.GridStyled>
          <Grid rowGap="24px">
            <DataTableWithScrollBar data={curveConfigurationData} columns={transformedColumns} rowKey={PDSCONSTANT.CURVE_CONFIGURATION_TABLE_ROW_KEY} />
            <DataTablePagination currentPage={paginationFilter.PageNumber} pageSize={paginationFilter.PageSize} total={paginationFilter.TotalRecords} setPage={setPage} onPageChange={handlePaginationFilter} />
          </Grid>
        </Styled.SideGrid></Styled.CenteredContainer></Styled.ContentContainer></Styled.InnerContainer>
    <ToastContainer />
    <Styled.ModalStyled destroyOnClose={true} description={PDSCONSTANT.EXPORT_TO_ALIGNE_CONFIRMATION_TEXT} onClose={onCloseModal} onPrimaryActionClick={ExportSelectedCurveData} onSecondaryActionClick={onCloseModal} title={PopupTitle} open={openModal} >{datePicker}{checkboxMarketCorrection}{popupTable}{inCompleteCurveExportWarning}</Styled.ModalStyled>
    <Modal  {...args} onClose={onCloseConfirmationModal} open={openConfirmationModal}><Styled.ImgStyled src={imgUrl}></Styled.ImgStyled><Styled.HeaderConfirmationStyled>Action successful</Styled.HeaderConfirmationStyled><p>{PDSCONSTANT.EXPORTED_TO_ALIGNE_CONFIRMATION_TEXT}</p></Modal>
    <ToastContainer />
  </>);
}




