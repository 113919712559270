// Constants file used for various labels and properties 
export const PDSCONSTANT = {
  // Represent label name for Public Toggle button 
  PUBLIC_BTN_LABEL: 'Public',
  // Unique row key for Curve Viewer
  CURVE_VIEWER_TABLE_ROW_KEY: '_id',
  // Unique row key for Curve Configuration
  CURVE_CONFIGURATION_TABLE_ROW_KEY: 'CurveName',
  // Unique row key for Timezone
  REFEREMCE_TIMEZONE_TABLE_ROW_KEY: 'Identifier',
  // Name value for Power Commodity
  POWER_COMMODITY_NAME_VALUE: 'Power',
  // Name value for FX Commodity
  FX_COMMODITY_NAME_VALUE: 'FX',
  // Name value for FX Commodity
  IR_COMMODITY_NAME_VALUE: 'Interest Rate',
  // Name value for Volatitlity curve type
  VOLATILITY_CURVE_TYPE_VALUE: 'Volatility',
  // Name value for Audit History
  REFERENCE_ATTRIBUTE_AUDIT_DRAWER_HEADER_LABEL: 'Audit History',
  // Curve View Side Drawer Header Text
  CURVE_VWR_SIDE_DRAWER_HEADER_LABEL: 'Curve Data',
  // Set the default as enable for Toggle button
  ENABLE_DEFAULT_TOGGLE_BTN: 'ENABLE',
  // Tooltip text for PDS Last Updated Date Header
  TOOLTIP_TEXT_PDS_LAST_UPDATED: 'Last updated time in Price Data Store (PDS)',
  // Tooltip text for Source Last Updated Date Header
  TOOLTIP_TEXT_SOURCE_LAST_UPDATED: 'Last updated time in the source system',
  // Tooltip text for Export Initiated Date Header
  TOOLTIP_TEXT_EXPORT_INITIATED_DATE: 'Time(in UTC) at which export to Aligne was initiated by PDS',
  // Tooltip text for Export Status Updated Date Header
  TOOLTIP_TEXT_EXPORT_STATUS_UPDATED_DATE: 'Time(in UTC) at at which recent export status was updated',
  // Tooltip text for Total time Elapsed
  TOOLTIP_TEXT_EXPORT_TOTAL_TIME_ELAPSED: 'Total time(in UTC) elapsed in Aligne Export',
  // Tooltip text for Add To Market Export to Aligne button on Manual Export page
  TOOLTIP_TEXT_ADD_TO_MARKET_BTN: 'Please filter by Add To Market set to TRUE to enable this button',
  // Tooltip text for PDS Curve Is Complete
  TOOLTIP_TEXT_IS_CURVE_COMPLETE_HEADER: 'Represents whether all the Curve Price Points ingested from Source system',
  // Tooltip text for PDS Curve Is Complete
  TOOLTIP_TEXT_IS_CURVE_COMPLETE: 'Parts of curve not ingested from source system',
  // Example Text for Curve Name Text box
  SUB_TEXT_CURVE_NAME: 'e.g. Data Provider_Location_DeliveryPoint _Currency_Granularity_Price Type',
  // Example Text for Calendar Description
  SUB_TEXT_CALENDAR_DESCRIPTION: 'e.g. Christmas Day, May Day',
  // Example Text for Calendar name
  SUB_TEXT_CALENDAR_NAME: 'e.g. UK',
  // Example Text for Source Curve Name
  SUB_TEXT_SOURCE_CURVE_NAME: 'e.g. for ZEMA sourced curves this should be the ZEMA curve name ‘Argus ​- ​Gas ​- ​CEGH ​VTP​– ​Months’',
  // Default Date format
  DATE_FORMAT: 'DD/MM/YYYY',
  // Curve Configuration Creation Success Message
  CURVE_CONFIG_CREATE_SUCCESS: 'Curve Configuration Created Successfully !',
  // Curve Configuration Update Success Message
  CURVE_CONFIG_UPDATE_SUCCESS: 'Curve Configuration updated Successfully !',
  // Price title for Standard Curve 
  STANDARD_CURVE_PRICE_TITLE: 'Price',
  // Price title for VOlatility Curve 
  Volatility_CURVE_PRICE_TITLE: 'Vol/Settle',
  //Export file name for productcurveconfig
  EXPORT_FILENAME_PRODUCTCURVECONFIG: "PDS_ProductCurveConfig_Export",
  //Export file name for Calendar Day Data
  EXPORT_FILENAME_CALENDARDAYDATA: "PDS_CalendarDayData_Export",
  //Export file name for productcurve
  EXPORT_FILENAME_PRODUCTCURVE: "PDS_ProductCurve_Export",
  //Export file name for alignecurve
  EXPORT_FILENAME_ALIGNECURVECONFIG: "PDS_AligneCurve_Export",
  //Export file name for aligneexportreport
  EXPORT_FILENAME_ALIGNEEXPORTREPORT: "PDS_AligneExport_Report",
  //Export file name for granullarity
  EXPORT_FILENAME_GRANULARITY_REFERENCE_DATA: "PDS_Granularity_ReferenceData_Export",
  //Export file name for CurveType
  EXPORT_FILENAME_CURVETYPE_REFERENCE_DATA: "PDS_CurveType_ReferenceData_Export",
  //Export file name for Commodity
  EXPORT_FILENAME_COMMODITY_REFERENCE_DATA: "PDS_Commodity_ReferenceData_Export",
  //Export file name for Commodity Delivery
  EXPORT_FILENAME_COMMODITY_DELIVERY_POINT_REFERENCE_DATA: "PDS_CommodityDeliveryPoint_ReferenceData_Export",
  //Export file name for Currency
  EXPORT_FILENAME_CURRENCY_REFERENCE_DATA: "PDS_Currency_ReferenceData_Export",
  //Export file name for Data Provider
  EXPORT_FILENAME_DATA_PROVIDER_REFERENCE_DATA: "PDS_DataProvider_ReferenceData_Export",
  //Export file name for Location
  EXPORT_FILENAME_LOCATION_REFERENCE_DATA: "PDS_Location_ReferenceData_Export",
  //Export file name for Price Type
  EXPORT_FILENAME_PRICE_TYPE_REFERENCE_DATA: "PDS_PriceType_ReferenceData_Export",
  //Export file name for Profile
  EXPORT_FILENAME_PROFILE_REFERENCE_DATA: "PDS_Profile_ReferenceData_Export",
  //Export file name for Unit
  EXPORT_FILENAME_UNIT_REFERENCE_DATA: "PDS_Unit_ReferenceData_Export",
  //Export file name for Source Provider
  EXPORT_FILENAME_SOURCE_PROVIDER_REFERENCE_DATA: "PDS_SourceProvider_ReferenceData_Export",
  //Export file name for timezone
  EXPORT_FILENAME_TIMEZONE_REFERENCE_DATA: "PDS_Timezone_ReferenceData_Export",
  //Export file name for audit history
  EXPORT_FILENAME_AUDITHISTORY: "PDS_ReferenceData_AuditHistory",
  // Example Text for Commodity Profile
  SUB_TEXT_COMMODITY_PROFILE: "Enabled when Commodity is 'Power'",
  // Example Text for Contract Option
  SUB_TEXT_CONTRACT_OPTION: "Enabled when Curve Type is 'Volatility'",
  // Mandatory sub text for High Granularities
  SUB_TEXT_MANDATORY_FIELD_FOR_HIGH_GRANULARITIES: "Mandatory if Granularity is 15, 30 or 60 min",
  // Mandatory sub text for Secondary Currency
  SUB_TEXT_MANDATORY_FIELD_FOR_SECONDARY_CURRENCY: "Mandatory if Commodity is FX",
  // Mandatory sub text for Secondary Currency
  SUB_TEXT_MANDATORY_FIELD_FOR_TIMEZONE: "Mandatory for Commodity other than FX or Interest Rate",
  // Volatility Import Type
  VOL_IMPORT_TYPE: "TRADEVOL2",
  // PRCFWD Import Type
  PRCFWD_IMPORT_TYPE: "PRCFWD",
  // PRCPOW FWD Import Type
  PRCPOW_FWD_IMPORT_TYPE: "PRCPOW_FWD",
  // PRCPOW SPOT Import Type
  PRCPOW_SPOT_IMPORT_TYPE: "PRCPOW_SPOT",
  // PRCPOW IMB Import Type
  PRCPOW_IMB_IMPORT_TYPE: "PRCPOW_IMB",
  // PSETTLE Import Type
  PSETTLE_IMPORT_TYPE: "PSETTLE",
  // Aligne Import Type Sub text
  SUB_TEXT_IMPORT_TYPE_OPTION: "Enabled when Aligne Import Type is 'TRADEVOL2'",
  //help text
  SUB_TEXT_ALIGNE_SETTLE: "Mandatory if Aligne Import Type is 'PRCFWD' or 'PRCPOW' ",
  //ALigne  Curve Configuration Creation Success Message
  ALIGNE_CURVE_CONFIG_CREATE_SUCCESS: 'Aligne Curve Configuration created successfully !',
  //ALigne  Curve Configuration Update Success Message
  ALIGNE_CURVE_CONFIG_UPDATE_SUCCESS: 'Aligne Curve Configuration updated successfully !',
  // ISO Date Format
  ISO_DATE_FORMAT: "YYYY-MM-DDTHH:mm:ss[Z]",
  // Reset filter button identifier
  RESET_BTN_IDENTIFIER: '[title="Clear filters"]',
  // Seven Days before Current Date
  SEVEN_DAYS_BEFORE_CURRENT_DATE: 7,
  // One Day before Current Date
  ONE_DAY_BEFORE_CURRENT_DATE: 1,
  // Default Time format
  TIME_FORMAT: 'HH:mm:ss',
  // Default Page Size
  DEFAULT_PAGE_SIZE: 10,
  // Decimal Rounding off
  DECIMAL_ROUNDING_OFF: 2,
  //Placeholder value for datepicker
  ADD_TO_MARKET_DATEPICKER_PLACEHOLDER: "Choose date for add to market export to Aligne",
  //Placeholder value for datepicker
  EDIT_MATURITY_DATEPICKER_PLACEHOLDER: "Choose date for export to Aligne",
  //confirmation text to export data to Aligne 
  EXPORT_TO_ALIGNE_CONFIRMATION_TEXT: "Are you sure you want to send data to Aligne?",
  //warning to export incomplete curves
  EXPORT_INCOMPLETE_CURVES_TO_ALIGNE: "Warning: One or more incomplete curves are about to be exported!",
  //confirmation message for Data exported to Aligne 
  EXPORTED_TO_ALIGNE_CONFIRMATION_TEXT: "Selected curves have been exported to SETA for import to Aligne.Please check Aligne exports report screen to confirm if import was successful",
  // Calendar Configuration Creation Success Message
  CALENDAR_CONFIG_CREATE_SUCCESS: 'Calendar Configuration created successfully !',
  // CALENDAR Configuration Update Success Message
  CALENDAR_CONFIG_UPDATE_SUCCESS: 'Calendar Configuration updated successfully !',
  // Tooltip text for PDS Min Effective Date Header
  TOOLTIP_TEXT_MIN_EFFECTIVE_DATE: 'Date for oldest curve version held in PDS',
  // Tooltip text for PDS Max Effective Date Header
  TOOLTIP_TEXT_MAX_EFFECTIVE_DATE: 'Date for most recent curve version stored in PDS',
  // Tooltip text for PDS Min Price Start Date Header
  TOOLTIP_TEXT_MIN_PRICE_START_DATE: 'Oldest forward date on a curve currently held in the PDS across all curve versions',
  // Tooltip text for PDS Max Price Start Date Header
  TOOLTIP_TEXT_MAX_PRICE_START_DATE: 'Forward date furthest in the future thats currently held in PDS across all curve versions',
  //Aligne MasterData keys
  ALIGNE_MARKET: "Market",
  ALIGNE_COMPONENT: "Component",
  ALIGNE_PSET: "PSet",
  ALIGNE_ZEROCAL: "ZeroCal",
  ALIGNE_ALIGNEIMPORTTYPE: "AligneImportType",
  ALIGNE_CONTRACTOPTIONS: "ContractOptions",
  ALIGNE_SECONDARYMARKET: "SecondaryMarket",
  ALIGNE_SECONDARYCOMPONENT: "SecondaryComponent",
  ALIGNE_UNIT: "Unit",
  ALIGNE_EXPORTSCHEDULE: "ExportSchedule",
  ALIGNE_GRANULARITY: "Granularity",
  ALIGNE_CURVETYPE: "CurveType",
  ALIGNE_EXPORTSTATUS: "ExportStatus",
  ALIGNE_EXPORTTYPE: "ExportType",
  ZEROCAL: "FALSE",
  ADDTOMARKET: "FALSE",

  //CurveConfiguration Filters

  CURVECONFIGURATION_GRANULARITY: "Granularity",
  CURVECONFIGURATION_CURVETYPE: "CurveType",
  CURVECONFIGURATION_PRICETYPE: "PriceType",
  CURVECONFIGURATION_CURRENCY: "Currency",
  CURVECONFIGURATION_UNIT: "Unit",
  CURVECONFIGURATION_LOCATION: "Location",
  CURVECONFIGURATION_COMMODITY: "Commodity",
  CURVECONFIGURATION_DELIVERYPOINT: "DeliveryPoint",
  CURVECONFIGURATION_DATAPROVIDER: "DataProvider",
  CURVECONFIGURATION_SOURCE: "Source",
  CURVECONFIGURATION_PROFILE: "Profile",

  // PDS Hourly Granularities
  PDS_HOURLY_GRANULARITITES: ["15 min", "30 min", "60 min"],

  // Curve Reference Creation EditSuccess Message
  CURVE_REFERENCE_CREATE_EDIT_SUCCESS: 'Curve Reference Saved Successfully !',

  // Reference Attribute Name
  CURVE_GRANULARITY_REFERENCE_ATTRIBUTE: "Granularity",
  CURVE_TYPE_REFERENCE_ATTRIBUTE: "CurveType",
  CURVE_PRICE_TYPE_REFERENCE_ATTRIBUTE: "PriceType",
  CURVE_CURRENCY_REFERENCE_ATTRIBUTE: "Currency",
  CURVE_UNIT_REFERENCE_ATTRIBUTE: "Unit",
  CURVE_LOCATION_REFERENCE_ATTRIBUTE: "Location",
  CURVE_COMMODITY_REFERENCE_ATTRIBUTE: "Commodity",
  CURVE_COMMODITY_DELIVERY_POINT_REFERENCE_ATTRIBUTE: "CommodityDeliveryPoint",
  CURVE_PROFILE_REFERENCE_ATTRIBUTE: "Profile",
  CURVE_DATA_PROVIDER_REFERENCE_ATTRIBUTE: "DataProvider",
  CURVE_SOURCE_PROVIDER_REFERENCE_ATTRIBUTE: "Source",


  // PDS error message

  PDS_INVALID_MATURITY_DATE_ERROR_MESSAGE: "Invalid Maturity Date. Please check the date and try again.",
  PDS_INVALID_MKT_COMP_ERROR_MESSAGE: "Invalid Mkt/Comp. Please check Aligne mappings.",
  PDS_INVALID_UNIT_ERROR_MESSAGE: "Invalid Unit. Please check Aligne mappings.",
  PDS_EOF_ERROR_MESSAGE: "Technical error. Contact PDS support team.",
  PDS_CONVERT_XML_ERROR_MESSAGE: "Technical error. Contact PDS support team.",
  PDS_SERVER_DOWN_ERROR_MESSAGE: "Technical error. Contact PDS support team.",
  PDS_INVALID_PASSWORD_ERROR_MESSAGE: "Technical error. Contact PDS support team.",
  PDS_DAY_CLOSED: "Aligne is closed for this period.",


  // ALIGNE Error
  ALIGNE_INVALID_MATURITY_DATE_ERROR_MESSAGE: "invalid maturity",
  ALIGNE_INVALID_MKT_COMP_ERROR_MESSAGE: "invalid mkt/comp",
  ALIGNE_INVALID_UNIT_ERROR_MESSAGE: "invalid unit",
  ALIGNE_EOF_ERROR_MESSAGE: "Unexpected end of file",
  ALIGNE_CONVERT_XML_ERROR_MESSAGE: "Convert2Xml Process has timed out",
  ALIGNE_SERVER_DOWN_ERROR_MESSAGE: "not running",
  ALIGNE_INVALID_PASSWORD_ERROR_MESSAGE: "Invalid User Id or Password",
  ALIGNE_DAY_CLOSED: "Day is closed: Prices cannot be imported"


}