import {
  Checkbox,
  ColumnsType,
  RecordType,
  Tooltip,
} from "@sede-x/shell-ds-react-framework";
import { PDSCONSTANT } from "../../common/constants";
import { ICurveConfigurationColumnPropsExtensions } from "../../interfaces/ICurveConfigurationColumnPropsExtension";

// Columns for Curve Configuration Table
export const CurveConfigurationColumnsHelper = (
  props: ICurveConfigurationColumnPropsExtensions
): ColumnsType<RecordType> => {
  const curveConfigurationColumns: ColumnsType<RecordType> = [
    {
      dataIndex: "IsChecked",
      key: "IsChecked",
      render: props.renderCheckbox,
      title: (
        <Checkbox
          crossOrigin="false"
          size="small"
          label=""
          checked={props.headerCheckbox}
          onChange={props.handleCheckboxAll}
          onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}
        />
      ),
    },
    {
      dataIndex: "CurveName",
      key: "CurveName",
      title: "Name",
      sorter: {
        compare: (a, b): number => {
          if (a.CurveName !== b.CurveName) {
            return a.CurveName < b.CurveName ? -1 : 1;
          }
          return 0;
        },
      },
      sortDirections: ["ascend", "descend"],
      render: props.renderLinkValue,
    },
    {
      dataIndex: "Granularity",
      key: "Granularity",
      title: "Granularity",
      sorter: {
        compare: (a, b): number => {
          if (a.Granularity !== b.Granularity) {
            return a.Granularity < b.Granularity ? -1 : 1;
          }
          return 0;
        },
      },
      sortDirections: ["ascend", "descend"],
      render: props.renderValue,
    },
    {
      dataIndex: "CurveType",
      key: "CurveType",
      title: "Curve Type",
      sorter: {
        compare: (a, b): number => {
          if (a.CurveType !== b.CurveType) {
            return a.CurveType < b.CurveType ? -1 : 1;
          }
          return 0;
        },
      },
      sortDirections: ["ascend", "descend"],
      render: props.renderValue,
    },
    {
      dataIndex: "ContractOption",
      key: "ContractOption",
      title: "Put/Call",
      sorter: {
        compare: (a, b): number => {
          if (a.ContractOption !== b.ContractOption) {
            return a.ContractOption < b.ContractOption ? -1 : 1;
          }
          return 0;
        },
      },
      sortDirections: ["ascend", "descend"],
      render: props.renderValue,
    },
    {
      dataIndex: "PriceType",
      key: "PriceType",
      title: "Price Type",
      sorter: {
        compare: (a, b): number => {
          if (a.PriceType !== b.PriceType) {
            return a.PriceType < b.PriceType ? -1 : 1;
          }
          return 0;
        },
      },
      sortDirections: ["ascend", "descend"],
      render: props.renderValue,
    },
    {
      dataIndex: "Currency",
      key: "Currency",
      title: "Currency",
      sorter: {
        compare: (a, b): number => {
          if (a.Currency !== b.Currency) {
            return a.Currency < b.Currency ? -1 : 1;
          }
          return 0;
        },
      },
      sortDirections: ["ascend", "descend"],
      render: props.renderValue,
    },
    {
      dataIndex: "UnitOfMeasure",
      key: "UnitOfMeasure",
      title: "Unit",
      sorter: {
        compare: (a, b): number => {
          if (a.Unit !== b.Unit) {
            return a.Unit < b.Unit ? -1 : 1;
          }
          return 0;
        },
      },
      sortDirections: ["ascend", "descend"],
      render: props.renderValue,
    },
    {
      dataIndex: "Location",
      key: "Location",
      title: "Location",
      sorter: {
        compare: (a, b): number => {
          if (a.Location !== b.Location) {
            return a.Location < b.Location ? -1 : 1;
          }
          return 0;
        },
      },
      sortDirections: ["ascend", "descend"],
      render: props.renderValue,
    },
    {
      dataIndex: "Commodity",
      key: "Commodity",
      title: "Commodity",
      sorter: {
        compare: (a, b): number => {
          if (a.Commodity !== b.Commodity) {
            return a.Commodity < b.Commodity ? -1 : 1;
          }
          return 0;
        },
      },
      sortDirections: ["ascend", "descend"],
      render: props.renderValue,
    },
    {
      dataIndex: "CommodityDeliveryPoint",
      key: "CommodityDeliveryPoint",
      title: "Delivery Point",
      sorter: {
        compare: (a, b): number => {
          if (a.CommodityDeliveryPoint !== b.CommodityDeliveryPoint) {
            return a.CommodityDeliveryPoint < b.CommodityDeliveryPoint ? -1 : 1;
          }
          return 0;
        },
      },
      sortDirections: ["ascend", "descend"],
      render: props.renderValue,
    },
    {
      dataIndex: "CommodityProfile",
      key: "CommodityProfile",
      title: "Profile",
      sorter: {
        compare: (a, b): number => {
          if (a.CommodityProfile !== b.CommodityProfile) {
            return a.CommodityProfile < b.CommodityProfile ? -1 : 1;
          }
          return 0;
        },
      },
      sortDirections: ["ascend", "descend"],
      render: props.renderValue,
    },
    {
      dataIndex: "MinEffectiveDate",
      key: "MinEffectiveDate",
      title: (
        <Tooltip
          placeholder=""
          placement="topLeft"
          overlay={PDSCONSTANT.TOOLTIP_TEXT_MIN_EFFECTIVE_DATE}
          onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}
        >
          <span>MinEffectiveDate</span>
        </Tooltip>
      ),
      sorter: {
        compare: (a, b): number => {
          if (a.MinEffectiveDate !== b.MinEffectiveDate) {
            return a.MinEffectiveDate < b.MinEffectiveDate ? -1 : 1;
          }
          return 0;
        },
      },
      sortDirections: ["ascend", "descend"],
      render: props.renderDate,
    },

    {
      dataIndex: "MaxEffectiveDate",
      key: "MaxEffectiveDate",
      title: (
        <Tooltip
          placeholder=""
          placement="topLeft"
          overlay={PDSCONSTANT.TOOLTIP_TEXT_MAX_EFFECTIVE_DATE}
          onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}
        >
          <span>MaxEffectiveDate</span>
        </Tooltip>
      ),
      sorter: {
        compare: (a, b): number => {
          if (a.MaxEffectiveDate !== b.MaxEffectiveDate) {
            return a.MaxEffectiveDate < b.MaxEffectiveDate ? -1 : 1;
          }
          return 0;
        },
      },
      sortDirections: ["ascend", "descend"],
      render: props.renderDate,
    },

    {
      dataIndex: "MinPricePeriodStartDate",
      key: "MinPricePeriodStartDate",
      title: (
        <Tooltip
          placeholder=""
          placement="topLeft"
          overlay={PDSCONSTANT.TOOLTIP_TEXT_MIN_PRICE_START_DATE}
          onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}
        >
          <span>MinPricePeriodStartDate</span>
        </Tooltip>
      ),
      sorter: {
        compare: (a, b): number => {
          if (a.MinPricePeriodStartDate !== b.MinPricePeriodStartDate) {
            return a.MinPricePeriodStartDate < b.MinPricePeriodStartDate ? -1 : 1;
          }
          return 0;
        },
      },
      sortDirections: ["ascend", "descend"],
      render: props.renderDate,
    },

    {
      dataIndex: "MaxPricePeriodEndDate",
      key: "MaxPricePeriodEndDate",
      title: (
        <Tooltip
          placeholder=""
          placement="topLeft"
          overlay={PDSCONSTANT.TOOLTIP_TEXT_MAX_PRICE_START_DATE}
          onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}
        >
          <span>MaxPricePeriodStartDate</span>
        </Tooltip>
      ),
      sorter: {
        compare: (a, b): number => {
          if (a.MaxPricePeriodEndDate !== b.MaxPricePeriodEndDate) {
            return a.MaxPricePeriodEndDate < b.MaxPricePeriodEndDate ? -1 : 1;
          }
          return 0;
        },
      },
      sortDirections: ["ascend", "descend"],
      render: props.renderDate,
    },
    {
      dataIndex: "TZIdentifier",
      key: "TZIdentifier",
      title: "Time Zone",
      sorter: {
        compare: (a, b): number => {
          if (a.TZIdentifier !== b.TZIdentifier) {
            return a.TZIdentifier < b.TZIdentifier ? -1 : 1;
          }
          return 0;
        },
      },
      sortDirections: ["ascend", "descend"],
      render: props.renderValue,
    },
    {
      dataIndex: "IsActive",
      key: "IsActive",
      title: "Status",
      sorter: {
        compare: (a, b): number => {
          if (a.IsActive !== b.IsActive) {
            return a.IsActive < b.IsActive ? -1 : 1;
          }
          return 0;
        },
      },
      sortDirections: ["ascend", "descend"],
      render: props.renderStatus,
    },

    {
      dataIndex: "InterfaceApplication",
      key: "InterfaceApplication",
      title: "Source",
      sorter: {
        compare: (a, b): number => {
          if (a.InterfaceApplication !== b.InterfaceApplication) {
            return a.InterfaceApplication < b.InterfaceApplication ? -1 : 1;
          }
          return 0;
        },
      },
      sortDirections: ["ascend", "descend"],
      render: props.renderValue,
    },

    {
      dataIndex: "UpdatedDate",
      key: "UpdatedDate",

      title: (
        <Tooltip
          placeholder=""
          placement="topLeft"
          overlay={PDSCONSTANT.TOOLTIP_TEXT_PDS_LAST_UPDATED}
          onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}
        >
          <span>Last Updated</span>
        </Tooltip>
      ),
      sorter: {
        compare: (a, b): number => {
          if (a.UpdatedDate !== b.UpdatedDate) {
            return a.UpdatedDate < b.UpdatedDate ? -1 : 1;
          }
          return 0;
        },
      },
      sortDirections: ["descend", "ascend"],
      defaultSortOrder: "ascend",
      render: props.renderDateAndTimeValue,
    },
    {
      dataIndex: "UpdatedBy",
      key: "UpdatedBy",
      title: "UpdatedBy",
      sorter: {
        compare: (a, b): number => {
          if (a.UpdatedBy !== b.UpdatedBy) {
            return a.UpdatedBy < b.UpdatedBy ? -1 : 1;
          }
          return 0;
        },
      },
      sortDirections: ["ascend", "descend"],
      render: props.renderValue,
    },
    {
      dataIndex: "IsAligneMappingExists",
      key: "IsAligneMappingExists",
      render: props.renderMappingExists,
      title: "Exports to Aligne?"
    },
    {
      dataIndex: "Clone",
      key: "Clone",
      render: props.renderButtonLink,
      title: "Clone",
    }
  ];
  return curveConfigurationColumns;
};
